import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        padding: theme.spacing(2),
    },
}));

export default () => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardHeader title="Configuration" />
            <CardContent>Test</CardContent>
        </Card>
    );
};
