import * as React from "react";
import {
    Show,
    List,
    TabbedShowLayout,
    Filter,
    Tab,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    required,
    TopToolbar,
    ListButton,
    EditButton,
    ReferenceManyField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SingleFieldList,
    ChipField,
    UrlField, DateField, FunctionField
} from 'react-admin';

import ActionButton from './actions/action_button';


const InstancesFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
    </Filter>
);

export const InstancesList = props => (
    <List {...props} filters={<InstancesFilter />} perPage={25}>
        <Datagrid rowClick="show">
            <ReferenceField source="server_id" reference="api/odoo/servers">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="gitlab_group" />
            <ReferenceField source="project_id" reference="api/odoo/projects">
                <TextField source="name" />
            </ReferenceField>
            <UrlField source="url" />
            <ReferenceManyField label="Databases" target="instance_id" reference="api/odoo/databases">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceManyField>
            <TextField source="password"/>
            <EditButton />
        </Datagrid>
    </List>
);

const InstancesTitle = ({ record }) => {
    return <span>Instance : {record ? `${record.name}` : ''}</span>;
};

const InstancesShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ActionButton action="ping" name="Ping" basePath={basePath} record={data} />
        <ActionButton action="get_databases" name="Get Databases" basePath={basePath} record={data}/>
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const InstancesShow = props => (
    <Show title={<InstancesTitle />} actions={<InstancesShowActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="Details">
                <ReferenceField source="server_id" reference="api/odoo/servers">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="name"/>
                <TextField source="gitlab_group" />
                <ReferenceField source="project_id" reference="api/odoo/projects">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="url"/>3
                <TextField source="password"/>
            </Tab>
            <Tab label="Databases" path="databases">
                <ReferenceManyField
                    reference="api/odoo/databases"
                    target="instance_id"
                    addLabel={false}
                    >
                    <Datagrid>
                        <TextField source="name" />
                        <TextField source="username" />
                        <TextField source="password" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Supervision" path="supervision">
                <ReferenceManyField
                    reference="api/odoo/pings"
                    target="instance_id"
                    addLabel={false}
                    >
                    <Datagrid>
                        <DateField source="created" showTime/>
                        <TextField source="http_server" />
                        <TextField source="http_status" />
                        <FunctionField label="Http" render={record => (record.http_time*1000).toFixed(2)+' ms' } />
                        <FunctionField label="Ping" render={record => (record.ping_time)?(record.ping_time).toFixed(2)+' ms': '' } />
                        <TextField source="html_status" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
     </Show>
);

const InstancesEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const InstancesEdit = props => (
    <Edit title={<InstancesTitle />} actions={<InstancesEditActions />} {...props}>
        <SimpleForm>
            <ReferenceInput source="server_id" reference="api/odoo/servers">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" validate={required()}/>
            <TextInput source="gitlab_group" />
            <ReferenceInput source="project_id" reference="api/odoo/projects">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="url"/>
            <TextInput source="password"/>
        </SimpleForm>
     </Edit>
);

const InstancesDefaultValue = { };
export const InstancesCreate = props => (
    <Create title="Create Instance" {...props}>
        <SimpleForm initialValues={InstancesDefaultValue}>
            <ReferenceInput source="server_id" reference="api/odoo/servers">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" validate={required()}/>
            <TextInput source="gitlab_group"/>
            <ReferenceInput source="project_id" reference="api/odoo/projects">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="url"/>
            <TextInput source="password"/>
        </SimpleForm>
    </Create>
);