import * as React from "react";
import {
    Show,
    List,
    TabbedShowLayout,
    Filter,
    Tab,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    required,
    TopToolbar,
    ListButton,
    EditButton,
    SelectInput,
    ArrayField,
    UrlField
} from 'react-admin';

import ActionButton from './actions/action_button';
import InfoIcon from "@material-ui/icons/Info";

const ProjectsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
    </Filter>
);

export const ProjectsList = props => (
    <List {...props} filters={<ProjectsFilter />} perPage={25}>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="version" />
            <TextField source="licence" />
            <EditButton />
        </Datagrid>
    </List>
);

const ProjectsTitle = ({ record }) => {
    return <span>Project : {record ? `${record.name}` : ''}</span>;
};

const ProjectsShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ActionButton action="gitlab" name="Gitlab informations" basePath={basePath} record={data} startIcon={<InfoIcon/>} />
        <ActionButton action="gitlab" method="post" name="Create on gitlab" basePath={basePath} record={data} startIcon={<InfoIcon/>} />
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const ProjectsShow = props => (
    <Show title={<ProjectsTitle />} actions={<ProjectsShowActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="Details">
                <TextField source="name" />
                <TextField source="version" />
                <TextField source="licence" />
            </Tab>
            <Tab label="Gitlab">
                <UrlField source="group" />
                <ArrayField source="repos" addLabel={false}>
                    <Datagrid>
                        <UrlField source="name"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
     </Show>
);

const versions = [
       { id: '5.0', name: '5.0' },
       { id: '6.0', name: '6.0' },
       { id: '6.1', name: '6.1' },
       { id: '7.0', name: '7.0' },
       { id: '8.0', name: '8.0' },
       { id: '9.0', name: '9.0' },
       { id: '10.0', name: '10.0' },
       { id: '11.0', name: '11.0' },
       { id: '12.0', name: '12.0' },
       { id: '13.0', name: '13.0' },
       { id: '14.0', name: '14.0' },
       { id: 'master', name: 'master' },
];
const licences = [
       { id: 'CE', name: 'CE' },
       { id: 'EE', name: 'EE' },
];

const ProjectsEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const ProjectsEdit = props => (
    <Edit title={<ProjectsTitle />} actions={<ProjectsEditActions />} {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()}/>
            <SelectInput source="version" choices={versions} />
            <SelectInput source="licence" choices={licences} />
        </SimpleForm>
     </Edit>
);

const ProjectsDefaultValue = { "licence":"EE", "version":"14.0" };
export const ProjectsCreate = props => (
    <Create title="Create Project" {...props}>
        <SimpleForm initialValues={ProjectsDefaultValue}>
            <TextInput source="name" validate={required()}/>
            <SelectInput source="version" choices={versions} />
            <SelectInput source="licence" choices={licences} />
        </SimpleForm>
    </Create>
);