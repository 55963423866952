// in src/authProvider.js
import decodeJwt from 'jwt-decode';
import axios from "axios";
import https from 'https';

const API_URL = process.env.REACT_APP_SERVICE_URL+"/api/auth/";
const agent = new https.Agent({
    rejectUnauthorized: false
});

export default {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        console.log("iciici")
        return axios
          .post(API_URL + "login", {
            username,
            password
          }, { httpsAgent: agent })
          .then(response => {
            if (response.data.accessToken) {
                const decodedToken = decodeJwt(response.data.accessToken);
                localStorage.setItem('token', response.data.accessToken);
                localStorage.setItem('permissions', decodedToken.permissions);
            }
            return response.data;
          });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};
