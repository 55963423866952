// in src/users.js
import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    Edit,
    SimpleForm,
    TextInput,
    BooleanField,
    BooleanInput,
    Create,
    required,
    TopToolbar,
    ListButton,
    PasswordInput
} from 'react-admin';

export const BackupList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
        </Datagrid>
    </List>
);

const BackupTitle = ({ record }) => {
    return <span>Backup : {record ? `${record.name}` : ''}</span>;
};

const BackupEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const BackupEdit = props => (
    <Edit title={<BackupTitle />} actions={<BackupEditActions />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" validate={required()}/>
        </SimpleForm>
     </Edit>
);

const BackupDefaultValue = { active: true, admin: false };
export const BackupCreate = props => (
    <Create {...props}>
        <SimpleForm initialValues={BackupDefaultValue}>
            <TextInput source="name" validate={required()}/>
        </SimpleForm>
    </Create>
);