import * as React from "react";
import {
    Show,
    List,
    SimpleShowLayout,
    Datagrid,
    TextField,
    TopToolbar,
    ListButton,
    ReferenceField,
    EditButton,
    DateField,
    BooleanField,
    FunctionField
} from 'react-admin';

const PingsRowStyle = (record, index) => ({
    backgroundColor: record.http_time*1000 > 1000 ? '#F08080' : 'white',
});

export const PingsList = props => (
    <List {...props} sort={{ field: 'created', order: 'DESC' }} perPage={25}>
        <Datagrid rowClick="show" rowStyle={PingsRowStyle}>
            <DateField source="created" showTime/>
            <DateField source="updated" showTime/>
            <ReferenceField source="instance_id" reference="api/odoo/instances">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="database_id" reference="api/odoo/databases">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="http_server" />
            <TextField source="http_status" />
            <TextField source="http_time" />
            <TextField source="ping_time" />
            <FunctionField label="Http" render={record => (record.http_time*1000).toFixed(2)+' ms' } />
            <FunctionField label="Ping" render={record => (record.ping_time)?(record.ping_time).toFixed(2)+' ms': '' } />
            <BooleanField source="html_status" />
        </Datagrid>
    </List>
);

const PingsTitle = ({ record }) => {
    return <span>Ping : {record ? `${record.name}` : ''}</span>;
};

const PingsShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const PingsShow = props => (
    <Show title={<PingsTitle />} actions={<PingsShowActions />} {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <ReferenceField source="instance_id" reference="api/odoo/instances">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="database_id" reference="api/odoo/databases">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="created" showTime/>
            <DateField source="updated" showTime/>
            <TextField source="http_server" />
            <TextField source="http_status" />
            <FunctionField label="Http" render={record => (record.http_time*1000).toFixed(2)+' ms' } />
            <FunctionField label="Ping" render={record => (record.ping_time)?(record.ping_time).toFixed(2)+' ms': '' } />
            <BooleanField source="html_status" />
        </SimpleShowLayout>
     </Show>
);
