import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useQueryWithStore} from "react-admin";
import { BackupList } from '../backups';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        padding: theme.spacing(2),
    },
}));

export default () => {
    const classes = useStyles();
    const Props = {
        basePath: "api/admin/backups",
        hasCreate: false,
        hasEdit: false,
        hasList: true,
        hasShow: false,
        exporter: false,
        actions: false,
        history: {},
        location: { pathname: "/", search: "", hash: "", state: undefined },
        match: { path: "/", url: "/", isExact: true, params: {} },
        options: {},
        permissions: null,
        resource: "api/admin/backups"
    };
    return (
        <Card className={classes.root}>
            <CardHeader title="Internal Backups" />
            <CardContent>
                <BackupList {...Props}/>
            </CardContent>
        </Card>
    );
};
