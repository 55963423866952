import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// import { useRedirect } from 'react-admin';
import { useNotify, useRefresh, fetchStart, fetchEnd, Button, Loading, Error} from 'react-admin';
import axios from "axios";
import fileDownload from 'js-file-download';
import https from "https";

const API_URL = process.env.REACT_APP_SERVICE_URL;
const agent = new https.Agent({
    rejectUnauthorized: false
});

const ActionButton = ({ basePath, record, action, name, startIcon, download, method }) => {
    const dispatch = useDispatch();
    // const redirect = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const handleClick = event => {
        event.stopPropagation();
        setLoading(true);
        dispatch(fetchStart());

        if (!method) {
            method ="get"
        }
        const args = { url: API_URL + basePath + "/" + record['id'] + "/" + action, method: method, config: { httpsAgent: agent }, headers: {'Authorization': 'Bearer '+localStorage.getItem('token')} };
        if (download) {
            args.responseType = 'blob'
        }
        return axios(args).then(response => {
                                if (response.headers['content-type'] === 'application/zip') {
                                    console.log(response)
                                    fileDownload(response.data, record.name);
                                }
                                notify('Action end', 'success');
                          })
                         .catch((error) => {
                             console.log(error.response)
                            const msg = (error.response &&
                                         error.response.data &&
                                         error.response.data.message) ||
                                         error.message ||
                                         error.toString()
                            setError(error);
                            notify('Error : '+msg, 'warning')
                          })
                         .finally(() => {
                            setLoading(false);
                            refresh();
                            dispatch(fetchEnd()); // stop the global loading indicator;
                          });
    };
    if (loading) return <Loading />;
    if (error) return <Error />;
    return <Button label={name} onClick={handleClick} startIcon={startIcon}/>;
};

export default ActionButton;