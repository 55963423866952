// in src/users.js
import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    required,
    TopToolbar,
    ListButton,
} from 'react-admin';

export const GroupsList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
        </Datagrid>
    </List>
);

const GroupsTitle = ({ record }) => {
    return <span>Group : {record ? `${record.name}` : ''}</span>;
};

const GroupsEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const GroupsEdit = props => (
    <Edit title={<GroupsTitle />} actions={<GroupsEditActions />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" validate={required()}/>
        </SimpleForm>
     </Edit>
);

const GroupsDefaultValue = { };
export const GroupsCreate = props => (
    <Create {...props}>
        <SimpleForm initialValues={GroupsDefaultValue}>
            <TextInput source="name" validate={required()}/>
        </SimpleForm>
    </Create>
);