// in src/users.js
import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    required,
    TopToolbar,
    ListButton, DateField,
} from 'react-admin';

export const EventList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" />
            <DateField source="created" showTime/>
        </Datagrid>
    </List>
);