// in src/App.js
import * as React from "react";
import { Route } from 'react-router-dom';
import TreeMenu from '@bb-tech/ra-treemenu';
import authProvider from './api/authProvider';
import dataProvider from './api/dataProvider';

import Dashboard from './dashboard/dashboard';
import Config from './admin/config';
import { Admin, Resource} from 'react-admin';
import { UserList, UserEdit, UserCreate} from './admin/users';
import { GroupsList, GroupsEdit, GroupsCreate} from './admin/groups';
import { BackupList, BackupEdit, BackupCreate} from './admin/backups';
import { EventList} from './admin/events';
import { ServersList, ServersShow, ServersEdit, ServersCreate} from './odoo/servers';
import { ConfigsList, ConfigsShow, ConfigsEdit, ConfigsCreate} from './odoo/configs';
import { InstancesList, InstancesShow, InstancesEdit, InstancesCreate} from './odoo/instances';
import { DatabasesList, DatabasesShow, DatabasesEdit, DatabasesCreate} from './odoo/databases';
import { ProjectsList, ProjectsShow, ProjectsEdit, ProjectsCreate} from './odoo/projects';
import { PingsList, PingsShow} from './odoo/pings';
import { BackupsList, BackupsShow} from './odoo/backups';

import SettingsIcon from '@material-ui/icons/Settings';
import EventsIcon from '@material-ui/icons/Visibility';

import UserIcon from '@material-ui/icons/Group';
import GroupsIcon from '@material-ui/icons/Security';
import ServersIcon from '@material-ui/icons/Computer';
import InstancesIcon from '@material-ui/icons/Storage';
import DatabaseIcon from '@material-ui/icons/Apps';
import PingsIcon from '@material-ui/icons/Event';
import BackupsIcon from '@material-ui/icons/Backup';
import GitHubIcon from '@material-ui/icons/GitHub';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import EventIcon from '@material-ui/icons/Event';

const route = [
        <Route
            key="config"
            path="/config"
            component={Config}
        />
]

const App = () => (
    <Admin dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider} menu={TreeMenu} customRoutes={route}>
        <Resource name="api/odoo/configs" list={ConfigsList} show={ConfigsShow} edit={ConfigsEdit} create={ConfigsCreate} icon={PermDataSettingIcon} options={{ "label": "Configs" }}/>
        <Resource name="api/odoo/projects" list={ProjectsList} show={ProjectsShow} edit={ProjectsEdit} create={ProjectsCreate} icon={GitHubIcon} options={{ "label": "Projects" }}/>
        <Resource name="api/odoo/servers" list={ServersList} show={ServersShow} edit={ServersEdit} create={ServersCreate} icon={ServersIcon} options={{ "label": "Servers" }}/>
        <Resource name="api/odoo/instances" list={InstancesList} show={InstancesShow} edit={InstancesEdit} create={InstancesCreate} icon={InstancesIcon} options={{ "label": "Instances"}}/>
        <Resource name="api/odoo/databases" list={DatabasesList} show={DatabasesShow} edit={DatabasesEdit} create={DatabasesCreate} icon={DatabaseIcon} options={{ "label": "Databases"}}/>
        <Resource name="api/odoo/backups" list={BackupsList} show={BackupsShow} icon={BackupsIcon} options={{ "label": "Backups"}}/>

        <Resource name="events" icon={EventsIcon} options={{ "label": "Events", "isMenuParent": true }} />
        <Resource name="api/odoo/pings" list={PingsList} show={PingsShow} icon={PingsIcon} options={{ "label": "Ping", "menuParent": "events" }}/>

        <Resource name="admin" icon={SettingsIcon} options={{ "label": "Admin", "isMenuParent": true }} />
        <Resource name="api/admin/users" list={UserList} edit={UserEdit} create={UserCreate} icon={UserIcon} options={{ "label": "Users", "menuParent": "admin" }}/>
        <Resource name="api/admin/groups" list={GroupsList} edit={GroupsEdit} create={GroupsCreate} icon={GroupsIcon} options={{ "label": "Groups", "menuParent": "admin" }}/>
        <Resource name="api/admin/backups" list={BackupList} edit={BackupEdit} create={BackupCreate} icon={BackupsIcon} options={{ "label": "Backups", "menuParent": "admin" }}/>
        <Resource name="api/admin/events" list={EventList} icon={EventIcon} options={{ "label": "Events", "menuParent": "admin" }}/>
        <Resource name="config" list={GroupsList} edit={GroupsEdit} create={GroupsCreate} icon={PermDataSettingIcon} options={{ "label": "Config", "menuParent": "admin" }}/>
    </Admin>
);

export default App;
