import * as React from "react";
import {
    Show,
    List,
    TabbedShowLayout,
    Filter,
    Tab,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    required,
    TopToolbar,
    ListButton,
    EditButton,
    ReferenceManyField,
    SingleFieldList,
    ChipField
} from 'react-admin';


const ServersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
    </Filter>
);

export const ServersList = props => (
    <List {...props} filters={<ServersFilter />} perPage={25}>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="hostname" />
            <TextField source="ip" />
            <ReferenceManyField label="Instances" target="server_id" reference="api/odoo/instances">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceManyField>
            <EditButton />
        </Datagrid>
    </List>
);

const ServersTitle = ({ record }) => {
    return <span>Server : {record ? `${record.name}` : ''}</span>;
};

const ServersShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const ServersShow = props => (
    <Show title={<ServersTitle />} actions={<ServersShowActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="Details">
                <TextField source="id" />
                <TextField source="name"/>
                <TextField source="hostname" />
                <TextField source="ip" />
            </Tab>
            <Tab label="Instances" path="instances">
                <ReferenceManyField
                    reference="api/odoo/instances"
                    target="server_id"
                    addLabel={false}
                    >
                    <Datagrid>
                        <TextField source="name" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
     </Show>
);

const ServersEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const ServersEdit = props => (
    <Edit title={<ServersTitle />} actions={<ServersEditActions />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" validate={required()}/>
            <TextInput source="hostname" />
            <TextInput source="ip" />
        </SimpleForm>
     </Edit>
);

const ServersDefaultValue = { };
export const ServersCreate = props => (
    <Create title="Create Server" {...props}>
        <SimpleForm initialValues={ServersDefaultValue}>
            <TextInput source="name" validate={required()}/>
            <TextInput source="hostname" />
            <TextInput source="ip" />
        </SimpleForm>
    </Create>
);