import * as React from "react";
import {
    Show,
    List,
    TabbedShowLayout,
    Filter,
    Tab,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    required,
    TopToolbar,
    SelectInput,
    EditButton,
    ListButton,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    ArrayField,
    UrlField,
    DateField,
    FunctionField,
    BooleanField,
    BooleanInput,
} from 'react-admin';

import ActionButton from './actions/action_button';
import BackupIcon from '@material-ui/icons/Backup';
import InfoIcon from "@material-ui/icons/Info";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import GetAppIcon from '@material-ui/icons/GetApp';

const DatabasesFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
    </Filter>
);

export const DatabasesList = props => (
    <List {...props} filters={<DatabasesFilter />} perPage={25}>
        <Datagrid rowClick="show">
            <ReferenceField source="server_id" reference="api/odoo/servers">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="instance_id" reference="api/odoo/instances">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <UrlField source="url" />
            <TextField source="username" />
            <TextField source="password" />
            <BooleanField source="primary" />
            <BooleanField source="auto_backup" />
            <BooleanField source="auto_ping" />
        </Datagrid>
    </List>
);

const DatabasesTitle = ({ record }) => {
    return <span>Database : {record ? `${record.name}` : ''}</span>;
};

// <Button color="primary" onClick={dataProvider.action(resource, {'ids':[data['id']]})}>Ping</Button>

const DatabasesShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ActionButton action="backup" name="Backup" basePath={basePath} record={data} startIcon={<BackupIcon/>}/>
        <ActionButton action="get_infos" name="Infos" basePath={basePath} record={data} startIcon={<InfoIcon/>}/>
        <ActionButton action="ping" name="Ping" basePath={basePath} record={data} startIcon={<NotListedLocationIcon/>}/>
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const DatabasesShow = props => (
    <Show title={<DatabasesTitle />} actions={<DatabasesShowActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="Details">
                <ReferenceField source="server_id" reference="api/odoo/servers">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="instance_id" reference="api/odoo/instances">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="name"/>
                <TextField source="url"/>
                <BooleanField source="primary" />
                <BooleanField source="auto_backup" />
                <BooleanField source="auto_ping" />
                <TextField source="username" />
                <TextField source="password" />
            </Tab>
            <Tab label="Modules" path="modules">
                <ArrayField source="ir.module.module" addLabel={false}>
                    <Datagrid>
                        <TextField source="name"/>
                        <TextField source="state"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Company" path="company">
                <ArrayField source="res.company" addLabel={false}>
                    <Datagrid>
                        <TextField source="name"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Users" path="users">
                <ArrayField source="res.users" addLabel={false}>
                    <Datagrid>
                        <TextField source="name"/>
                        <TextField source="login"/>
                        <TextField source="email"/>
                        <TextField source="company_id"/>
                        <TextField source="company_ids"/>
                        <TextField source="login_date"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Smtp" path="smtp">
                <ArrayField source="ir.mail_server" addLabel={false}>
                    <Datagrid>
                        <TextField source="name"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Fetchmail" path="fetchmail">
                <ArrayField source="fetchmail.server" addLabel={false}>
                    <Datagrid>
                        <TextField source="name"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Backups" path="backups">
                <ReferenceManyField
                    reference="api/odoo/backups"
                    target="database_id"
                    addLabel={false}
                    >
                    <Datagrid>
                        <DateField source="created" showTime/>
                        <TextField source="name" />
                        <FunctionField label="Create" render={record => (record.request_time).toFixed(2)+' s' } />
                        <FunctionField label="Download" render={record => (record.download_time).toFixed(2)+' s' } />
                        <FunctionField label="Size" render={record => (record.size/1000/1000).toFixed(2)+' Mo' } />
                        <BooleanField source="zip_ok" />
                        <BooleanField source="odoo_ok" />
                        <ActionButton action="get_file" name="Download" startIcon={<GetAppIcon/>} download={true}/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Supervision" path="supervision">
                <ReferenceManyField
                    reference="api/odoo/pings"
                    target="database_id"
                    addLabel={false}
                    >
                    <Datagrid>
                        <DateField source="created" showTime/>
                        <TextField source="http_server" />
                        <TextField source="http_status" />
                        <FunctionField label="Http" render={record => (record.http_time*1000).toFixed(2)+' ms' } />
                        <BooleanField source="html_status" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
     </Show>
);

const DatabasesEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const DatabasesEdit = props => (
    <Edit title={<DatabasesTitle />} actions={<DatabasesEditActions />} {...props}>
        <SimpleForm>
            <ReferenceInput source="instance_id" reference="api/odoo/instances">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" validate={required()}/>
            <BooleanInput source="primary" />
            <BooleanInput source="auto_backup" />
            <BooleanInput source="auto_ping" />
            <TextInput source="username" />
            <TextInput source="password" />
        </SimpleForm>
     </Edit>
);

const DatabasesDefaultValue = { };
export const DatabasesCreate = props => (
    <Create title="Create Database" {...props}>
        <SimpleForm initialValues={DatabasesDefaultValue}>
            <ReferenceInput source="instance_id" reference="api/odoo/instances">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" validate={required()}/>
            <BooleanInput source="primary" />
            <BooleanInput source="auto_backup" />
            <TextInput source="password" />
        </SimpleForm>
    </Create>
);