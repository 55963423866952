import * as React from "react";
import {
    Show,
    List,
    SimpleShowLayout,
    Datagrid,
    TextField,
    TextInput,
    TopToolbar,
    ListButton,
    ReferenceField,
    DateField,
    BooleanField,
    FunctionField,
    Filter,
} from 'react-admin';
import ActionButton from "./actions/action_button";
import GetAppIcon from '@material-ui/icons/GetApp';

const BackupsRowStyle = (record, index) => ({
    backgroundColor: record.size/1000/1000 < 1 ? '#F08080' : 'white',
});

const BackupsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
        <TextInput label="Today" source="created" defaultValue="2020-08-15" />
    </Filter>
);
        // <DateInput label="Date Start" source="created_gte" alwaysOn />
        // <DateInput label="Date Stop" source="created_lte" alwaysOn />

export const BackupsList = props => (
    <List {...props} filters={<BackupsFilter />} perPage={25}>
        <Datagrid rowClick="show" rowStyle={BackupsRowStyle}>
            <ReferenceField source="server_id" reference="api/odoo/servers">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="instance_id" reference="api/odoo/instances">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="database_id" reference="api/odoo/databases">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="created" showTime/>
            <TextField source="name" />
            <FunctionField label="Create" render={record => (record.request_time).toFixed(2)+' s' } />
            <FunctionField label="Download" render={record => (record.download_time).toFixed(2)+' s' } />
            <FunctionField label="Size" render={record => (record.size/1000/1000).toFixed(2)+' Mo' } />
            <BooleanField source="zip_ok" />
            <BooleanField source="odoo_ok" />
            <ActionButton action="get_file" name="Download" startIcon={<GetAppIcon/>} download={true}/>
        </Datagrid>
    </List>
);

const BackupsTitle = ({ record }) => {
    return <span>Backup : {record ? `${record.name}` : ''}</span>;
};

const BackupsShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ActionButton action="get_file" name="Download" basePath={basePath} record={data} startIcon={<GetAppIcon/>} download={true}/>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const BackupsShow = props => (
    <Show title={<BackupsTitle />} actions={<BackupsShowActions />} {...props}>
        <SimpleShowLayout>
            <ReferenceField source="server_id" reference="api/odoo/servers">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="instance_id" reference="api/odoo/instances">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="database_id" reference="api/odoo/databases">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="created" showTime/>
            <TextField source="name" />
            <FunctionField label="Create" render={record => (record.request_time).toFixed(2)+' s' } />
            <FunctionField label="Download" render={record => (record.download_time).toFixed(2)+' s' } />
            <FunctionField label="Size" render={record => (record.size/1000/1000).toFixed(2)+' Mo' } />
            <BooleanField source="zip_ok" />
            <BooleanField source="odoo_ok" />
        </SimpleShowLayout>
     </Show>
);
