import simpleRestProvider from 'ra-data-json-server';
import {fetchUtils} from "react-admin";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', 'Bearer '+localStorage.getItem('token'));
    return fetchUtils.fetchJson(url, options);
};


const simpleDataProvider = simpleRestProvider(process.env.REACT_APP_SERVICE_URL, httpClient);
const dataProvider = {
    ...simpleDataProvider,
    action: (resource, params) => {
        Promise.all(
            params.ids.map(id =>
                httpClient(`https://api.test.local/${resource}/${id}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) }))
    }
};

export default dataProvider;