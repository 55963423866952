import * as React from "react";
import AceEditor from "react-ace";
import * as ace from 'ace-builds/src-noconflict/ace';
import { LazyLog, ScrollFollow } from 'react-lazylog';
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-github";
import {
    useShowController,
    Show,
    List,
    TabbedShowLayout,
    Filter,
    Tab,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    required,
    TopToolbar,
    ListButton,
    EditButton,
} from 'react-admin';
import BackupIcon from "@material-ui/icons/Backup";
import ActionButton from "./actions/action_button";

ace.config.set('basePath', '/assets/ui/'); // suppress warning
const ConfigsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
    </Filter>
);

export const ConfigsList = props => (
    <List {...props} filters={<ConfigsFilter />} perPage={25}>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="instance_name" />
            <TextField source="path" />
            <EditButton />
        </Datagrid>
    </List>
);

const ConfigsTitle = ({ record }) => {
    return <span>Config : {record ? `${record.name}` : ''}</span>;
};

const ConfigsShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ActionButton action="start" name="Start" basePath={basePath} record={data} startIcon={<BackupIcon/>}/>
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const ConfigsShow = props => {
    const controllerProps = useShowController(props);
    var auth_header = new Headers();
    auth_header.append('Authorization', 'Bearer '+localStorage.getItem('token'));
    var url = "";
    if ( controllerProps.record ) {
        url = "http://api.dashboard.localhost/stream/odoo/configs/" + controllerProps.record.id;
    }
    return (
        <Show title={<ConfigsTitle/>} actions={<ConfigsShowActions/>} {...props}>
            <TabbedShowLayout>
                <Tab label="Details">
                    <TextField source="id"/>
                    <TextField source="name"/>
                    <TextField source="instance_name"/>
                    <TextField source="path"/>
                </Tab>
                <Tab label="File">
                    <AceEditor
                        mode="yaml"
                        width="100%"
                        height="1000px"
                        readOnly={true}
                        value={ (controllerProps.record && controllerProps.record.data) || "" }
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 4,
                            showPrintMargin: false,
                            hScrollBarAlwaysVisible: true,
                            vScrollBarAlwaysVisible: true
                        }}
                    />,
                </Tab>
                <Tab label="Logs">
                    <div style={{ height: 500, marginBottom:10 }}>
                        <ScrollFollow
                            startFollowing
                            render={({ onScroll, follow, startFollowing, stopFollowing }) => (
                                <LazyLog
                                extraLines={1}
                                enableSearch
                                height={700}
                                url={ (controllerProps.record && url) || ""}
                                fetchOptions={{ headers:auth_header }}
                                stream
                                onScroll={onScroll}
                                follow={follow} />
                            )}
                        />
                    </div>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}

const ConfigsEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const ConfigsEdit = props => (
    <Edit title={<ConfigsTitle />} actions={<ConfigsEditActions />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" validate={required()}/>
            <TextInput source="instance_name" />
            <TextInput source="path" />
        </SimpleForm>
     </Edit>
);

const ConfigsDefaultValue = { };
export const ConfigsCreate = props => (
    <Create title="Create Config" {...props}>
        <SimpleForm initialValues={ConfigsDefaultValue}>
            <TextInput source="name" validate={required()}/>
            <TextInput source="instance_name" />
            <TextInput source="path" />
        </SimpleForm>
    </Create>
);