// in src/users.js
import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    Edit,
    SimpleForm,
    TextInput,
    BooleanField,
    BooleanInput,
    Create,
    required,
    TopToolbar,
    ListButton,
    PasswordInput
} from 'react-admin';

export const UserList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="username" />
            <EmailField source="email" />
            <BooleanField source="admin" />
            <BooleanField source="active" />
        </Datagrid>
    </List>
);

const UserTitle = ({ record }) => {
    return <span>User : {record ? `${record.name}` : ''}</span>;
};

const UserEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export const UserEdit = props => (
    <Edit title={<UserTitle />} actions={<UserEditActions />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" validate={required()}/>
            <TextInput source="email" validate={required()}/>
            <TextInput source="username" validate={required()}/>
            <PasswordInput source="password" validate={required()}/>
            <BooleanInput source="admin" />
            <BooleanInput source="active" />
        </SimpleForm>
     </Edit>
);

const UserDefaultValue = { active: true, admin: false };
export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm initialValues={UserDefaultValue}>
            <TextInput source="name" validate={required()}/>
            <TextInput source="email" validate={required()}/>
            <TextInput source="username" validate={required()}/>
            <PasswordInput source="password" validate={required()}/>
            <BooleanInput source="admin" />
            <BooleanInput source="active" />
        </SimpleForm>
    </Create>
);