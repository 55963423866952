import * as React from "react";
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import Backups from './config/backups';
import Infos from './config/info';
import Users from './config/users';
import { makeStyles } from '@material-ui/core/styles';
import {UserCreate, UserEdit, UserList} from "./users";
import UserIcon from "@material-ui/icons/Group";
import {Resource} from "react-admin";
import { useQueryWithStore } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={3}
            >
                <Grid item xs={12}>
                    <Infos/>
                </Grid>
                <Grid item xs={6}>
                    <Backups/>
                </Grid>
                <Grid item xs={6}>
                    <Users/>
                </Grid>
            </Grid>
        </div>

    );
};